.m-b-20 {
  margin-bottom: 32px;
}
.login-block {
  padding: 30px 0;
  margin: 0 auto;
  background: url(../images/bg.jpg) no-repeat;
  background-size: cover;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .auth-box {
    &.card {
      background-color: #fff;
    }
    margin: 20px auto 0;
    max-width: 450px;
    .card-block {
      padding: 1.25rem;
      position: relative;
      .alert {
        padding: 6px 12px;
      }

      .form-group {
        margin-bottom: 30px;
        position: relative;
        .error {
          position: absolute;
          font-size: 13px;
          color: #ff0000;
        }
      }
    }
    .loaderDiv {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      left: 0;
      top: 0;
      .loader {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
      }
    }
  }
  .errorMsg {
    margin: 20px auto 0;
    max-width: 450px;
  }
}
