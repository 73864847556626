.main-card-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;

  .main-card {
    width: 375px;
    height: 175px;
    border: 1px solid #2196f3;
    box-shadow: 0 0 10px #00000011;
    padding: 20px 20px;
    border-radius: 7px;

    .card-num {
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 800;

      .hiden-card-numbers {
        height: 20px;
      }
    }

    .exp-date {
      padding-top: 10px;

      .label {
        font-weight: 700;
      }
    }

    .main-card-footer {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 5px;

      .card-type {
        font-weight: bold;
        letter-spacing: 3px;
      }
    }
  }

  .loaderDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    left: 0;
    top: 0;

    .loader {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translatey(-50%);
    }
  }
}

.error {
  color: red;
  font-size: small;
}

.row {
  margin-top: 7px;
}

.form-label {
  font-weight: bolder;
  font-size: 14px;
  margin-left: 2px;
  padding: 10px 0 5px;
  display: block;
}

.add-new-card {
  width: 390px;
  height: 175px;
  border: 3px dashed #2196f354;
  box-shadow: 0 0 10px #00000011;
  padding: 20px 20px;
  border-radius: 7px;
  font-size: 100px;
  cursor: pointer;
  color: #2196f3b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ccnumber,
#cvv,
#ccexp {
  input {
    border: 1px solid #ced4da;
    color: #495057;
    height: 39px;
    border-radius: 5px;
    padding-left: 12px;
    width: 10px
  }
}

.CollectJSInlineIframe {
  width: 99.9%
}

.Payment_form {
  .loaderDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    left: 0;
    top: 0;
    z-index: 99;

    .loader {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translatey(-50%);
    }
  }
}

.row.gap-1 {
  gap: 1px;
}



.payment-amount-table-container {
  padding: 10px;
  margin: 20px;
  width: 80%;
  box-shadow: 0 0 10px #0000002a;

  .payment-amount-table {
    width: 100%;

    thead {
      background-color: #4f656c;
      color: #fff;
      height: 40px;

    }

    th,
    td {
      padding: 10px;
    }

    .service-row td {
      padding-left: 30px !important;
    }

    tfoot {
      border-top: 1px solid #ccc;
      font-weight: 800;
      margin-top: 10px;
    }
  }
}

.payment-amount-main-container {
  padding: 10px;
  margin: 20px;
  width: 70%;
}

.payment-amount-container {
  align-items: center;
  background-color: #F8F8F8;
  width: 70%;
  padding: 20px;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 2px 1px 12px #888888;
  display: flex;
  justify-content: space-evenly;
}

.payment-amount-details {
  margin: 30px 0px;
  border: 1px solid #F8F8F8
}

.payment-amount-detail {
  margin: 30px 0px;
  border: 1px solid #D8D8D8
}

.payment-amount-heading,
.payment-amount-total {
  font-weight: bold;
}

.card-select-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .another-card {
    margin-left: 30px;
    color: #004cff;
  }
}

.select-wrapper {
  display: flex;
  width: 70%;
}

.card-select {
  width: 50%;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.added-cards {
  width: max-content;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 6px #91b7c4 inset;
  gap: 30px;

  .card-name {
    text-transform: uppercase;
    font-weight: 700;
  }

  .card-number {
    position: relative;
    top: 3px;
    font-weight: 700;
  }
}

.review-text {
  padding: 20px 0;
}

.payment-data {
  padding: 10px;
  margin: 20px;
  width: 80%;
  box-shadow: 0 0 10px #0000002a;

}

.tabs-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.float-dollor {
  width: max-content;
  padding-inline: 15px;
  height: 36px;
  line-height: 38px;
  cursor: pointer;
  background-color: #ffffff;
  color: #0685f7;
  border-radius: 8px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  margin-right: 5px;
  font-size: 16px;
  transition: all .8s;

}

.float-dollor:hover {
  background-color: #4f656c;
  color: #ffffff;
}


// .float-dollor-text:hover{
// color: #4f656c;
// }

.entry-form {
  width: 90%;
  background-color: #ffffff;
  box-shadow: 0 0 10px #0000002a;
  padding: 20px 20px;
  position: relative;

  .another-card {
    margin-left: 30px;
    color: #004cff;
    text-align: right;
    position: absolute;
    right: 16px;
    top: 12px;
  }

  .entry-form-checkbox {
    margin: 15px 0;
  }
}

.selected-card {
  margin: 20px;
  width: 45%;
  padding: 20px 20px;
  box-shadow: 0 0 10px #0000002a;

}

.payment-method {
  display: flex;
  gap: 20px;
}

.native-select {
  width: 100%;
}

.conformation-content {
  box-shadow: 0 0 10px #0000002a;
  text-align: center;
  width: 40%;
  margin: 20px 0;
  padding: 10px;

  .payment-transaction {
    padding: 12px;
    text-align: center;
    margin-top: 5px;
  }

  .confirmation-img {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #008000;
  }

  .unsuccessful-payment {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: red;
  }

  .response-success {
    margin: 10px 0;
    font-weight: bold;
    color: #008000;
  }

  .response-unsuccess {
    margin: 10px 0;
    font-weight: bold;
    color: red;
  }

  h4 {
    margin: 15px 0;
  }

  p {
    margin: 4px;
  }
}

.form-control:focus {
  box-shadow: none;

}