.messaging-module {
    background-color: #fff;
    .mbl-messages {
        height: calc(100vh - 259px) !important;
        .loaderDiv {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .list-group-item {
        position: relative;
        padding: 10px 15px;
        margin-bottom: -1px;
        background-color: transparent;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        .pull-left {
            padding-right: 10px;
            @media (max-width: 480px) {
                padding-right: 0;
            }
        }
        .media-body {
            overflow: hidden;
            .lgi-heading {
                font-size: 15px;
                margin-bottom: 0px;
                text-align: left;
            }
        }
    }
    .list-group-item:first-child {
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
    }
    .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    a.list-group-item,
    button.list-group-item {
        color: #555;
    }
    a.list-group-item .list-group-item-heading,
    button.list-group-item .list-group-item-heading {
        color: #333;
    }
    a.list-group-item:hover,
    button.list-group-item:hover,
    a.list-group-item:focus,
    button.list-group-item:focus {
        text-decoration: none;
        color: #555;
        background-color: #f7f7f7;
    }    
    .list-group-item.active,
    .list-group-item.active:hover,
    .list-group-item.active:focus {
        z-index: 0;
        color: #000;
        background-color: #f5f5f5;
        border-color: #e9e9e9;
    }
    .list-group-item.active .list-group-item-heading,
    .list-group-item.active:hover .list-group-item-heading,
    .list-group-item.active:focus .list-group-item-heading,
    .list-group-item.active .list-group-item-heading > small,
    .list-group-item.active:hover .list-group-item-heading > small,
    .list-group-item.active:focus .list-group-item-heading > small,
    .list-group-item.active .list-group-item-heading > .small,
    .list-group-item.active:hover .list-group-item-heading > .small,
    .list-group-item.active:focus .list-group-item-heading > .small {
        color: inherit;
    }
    .list-group-item.active .list-group-item-text,
    .list-group-item.active:hover .list-group-item-text,
    .list-group-item.active:focus .list-group-item-text {
        color: #ffffff;
    }
    .list-group-item-info {
        color: #31708f;
        background-color: #d9edf7;
    }
    a.list-group-item-info,
    button.list-group-item-info {
        color: #31708f;
    }
    a.list-group-item-info .list-group-item-heading,
    button.list-group-item-info .list-group-item-heading {
        color: inherit;
    }
    a.list-group-item-info:hover,
    button.list-group-item-info:hover,
    a.list-group-item-info:focus,
    button.list-group-item-info:focus {
        color: #31708f;
        background-color: #c4e3f3;
    }
    a.list-group-item-info.active,
    button.list-group-item-info.active,
    a.list-group-item-info.active:hover,
    button.list-group-item-info.active:hover,
    a.list-group-item-info.active:focus,
    button.list-group-item-info.active:focus {
        color: #fff;
        background-color: #31708f;
        border-color: #31708f;
    }
    .list-group-item-heading {
        margin-top: 0;
        margin-bottom: 5px;
    }
    .list-group-item-text {
        margin-bottom: 0;
        line-height: 1.3;
    }  

    /*-------------------------
        For Stupid IE9
    --------------------------*/

    .list-group {
        margin-bottom: 0;
    }
    .list-group .list-group-item {
        border: 0;
        margin: 0;
        padding: 15px 23px;
        width: 100%;
    }
    .list-group.lg-odd-black .list-group-item:nth-child(odd) {
        background-color: #f7f7f7;
    }
    .list-group.lg-even-black .list-group-item:nth-child(even) {
        background-color: #f7f7f7;
    }
    .messages {
        height: calc(100vh - 130px);
        position: relative;
        overflow: hidden;
        border: 1px solid #e1e1e1;
    }
    .messages header {
        min-height: 55px;
    }
    .messages header .actions {
        position: absolute;
        top: 12px;
        right: 13px;
        list-style: none;
        li {
            color: #47a8f5;
            font-size: 20px;
            &:hover {
                color: #2196f3;
            }
        }
    }
    /*-----------------------------------
        Sidebar
    ------------------------------------*/

    .m-sidebar {
        width: 300px;
        border-right: 1px solid #eee;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        /* Scrollbar fix */
        .loaderDiv {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    @media (max-width: 767px) {
        .m-sidebar {
            width: 60px;
        }

        .m-sidebar .list-group-item {
            padding: 10px !important;
        }

        .m-sidebar .ms-time {
            display: none;
        }
    }
    .m-sidebar header {
        background-color: #f7f7f7;
        padding: 20px;
    }
    .m-sidebar header h2 {
        line-height: 100%;
        font-size: 15px;
        margin: 0;
        text-transform: uppercase;
        font-weight: 400;
        @media (max-width: 480px) {
            display: none;
        }
    }
    .m-sidebar .list-group {
        height: calc(100% - 55px);
        margin-top: 2px;
    }
    .m-sidebar .mCSB_scrollTools {
        z-index: 2;
    }
    .ms-time {
        position: absolute;
        top: 16px;
        right: 25px;
        color: #777777;
    }
    /*-----------------------------------
        Body
    ------------------------------------*/

    .m-body {
        position: relative;
        height: 100%;
    }
    @media (min-width: 768px) {
        .m-body {
            padding-left: 300px;
        }
    }
    @media (max-width: 767px) {
        .m-body {
            padding-left: 60px;
        }
    }
    .mb-header {
        background-color: #f7f7f7;
        box-shadow: 0px -1px 0px 1px #eaeaea;
        position: relative;
        z-index: 2;
    }
    .mbh-user {
        padding: 7px 20px;
        display: flex;
        align-items: center;
        .active-group-name {
            margin-left: 5px;
            color: #000;
        }
    }
    .mbh-user > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        float: left;
        margin-right: 15px;
    }
    .mb-list {
        height: calc(100% - 55px);
        position: relative;
    }
    @media (max-width: 767px) {
        .mbl-messages {
            padding: 20px 20px 0;
        }
    }
    .mblm-item {
        position: relative;
        margin-bottom: 10px;
    }
    .mblm-item > div {
        display: inline-block;
        max-width: 70%;
        padding: 10px 15px;
        border-radius: 2px;
        position: relative;
        white-space: pre-wrap;
    }
    .mblm-item small {
        display: block;
        color: #777777;
        padding: 5px 0px;
    }
    .mblm-item-left > div {
        background-color: #eee;
        font-size: 13px;
        color: #5e5e5e;
        border-radius: 10px;
    }
    .mblm-item-right {
        text-align: right;
    }
    .mblm-item-right > div {
        background-color: #2196f3;
        color: #fff;
        font-size: 13px;
        border-radius: 10px;
        text-align: left;
    }
    .mblmi-img {
        margin: -10px -15px;
        cursor: pointer;
    }
    .mblmi-img img {
        max-width: 250px;
        border-radius: 2px;
    }
    .mbl-compose {
        border-top: 1px solid #eee;
        height: 70px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 65px 15px 15px;
    }
    .mbl-compose textarea {
        height: 100%;
        width: 100%;
        border-radius: 2px;
        border: 0;
        resize: none;
        background: #eee;
        padding: 5px 10px;
        color: #5e5e5e;
        -webkit-transition: background-color;
        -o-transition: background-color;
        transition: background-color;
        -webkit-transition-duration: 300ms;
        transition-duration: 300ms;
        &:focus {
            outline:none;
        }
    }
    .mbl-compose button {
        position: absolute;
        top: 14px;
        right: 15px;
        background: transparent;
        border: 0;
        font-size: 25px;
    }
    .mbl-compose button:hover {
        color: #333;
    }
    .no-message-yet {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        i {
            color: #47a8f5;
            font-size: 120px;
        }
    }
    .new-message-recevied {
        .MuiAvatar-colorDefault {
            background-color: #47a8f5;
        }
        .lgi-heading {
            color: #47a8f5;
        }
    }
}
